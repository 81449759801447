.slider-panel {
    position: relative;
    min-height: 510px;
    overflow: hidden;
    // @media @mobile-min {
    //     &:after {
    //         content: '';
    //         position: absolute;
    //         top: 0;
    //         right: 0;
    //         bottom: 0;
    //         left: 0;
    //         z-index: 1;
    //         opacity: .3;
    //     }
    // }
}

.slider-panel__img {
    // max-height: 510px;
    @media @mobile-min {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.slider-panel__overlay {
    background-color: rgba(255,255,255,.8);
    padding: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all ease .4s;
    @media @mobile-min {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        transform: translateX(100%);
        max-width: 350px;
    }
    @media @mobile {
        background-color: #eee;
    }
}

@media @mobile-min {
    .owl-item.active {
        .slider-panel__overlay {
            transform: translateX(0);
            transition-delay: .2s;
        }
    }
}

.slider-panel__icon-container {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
}

.slider-panel__icon {
    font-size: 30px !important;
}

.slider-panel__title {
    line-height: 1.5;
    margin-bottom: 10px;
    font-weight: 400;
}

.slider-panel__button {
    padding: 10px 20px;
    border: 2px solid;
    display: inline-block;
    border-radius: 4px;
    text-decoration: none !important;
    color: inherit;
    margin-top: 15px;
    .transition;
    &:hover {
        background-color: #FFF;
        border-color: #FFF;
        color: @color-dark;
    }
    &:before {
        display: none;
    }
}

.owl-dots {
    @media @mobile-min {
        position: absolute;
        left: 20px;
        bottom: 20px;
        right: 0;
        z-index: 10;
    }
    @media @mobile {
        margin: 10px 0 30px;
    }
}

.owl-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #FFF !important;
    margin-right: 10px;
    outline: none;
    @media @mobile {
        border-color: rgb(148, 148, 148) !important;
    }
    &.active {
        background-color: rgba(255,255,255,.5) !important;
        @media @mobile {
            background-color: rgb(148, 148, 148) !important;
        }
    }
}