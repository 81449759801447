.panel {
    // &--yellow {
    //     .panel-colour(@color: @color-yellow);
    //     // color: #FFF;
    // }
    &--green {
        .panel-colour(@color: @color-green);
        // color: #FFF;
    }
    &--blue {
        .panel-colour(@color: @color-purple);
        color: #FFF;
    }
    &--purple {
        .panel-colour(@color: @color-purple);
        color: #FFF;
    }
    &--warning, &--orange, &--yellow {
        .panel-colour(@color: @color-orange);
        // color: #FFF;
    }
}

.panel-colour(@color: #eee) {
    &.content-panel, &.cta-panel {
        background-color: @color;
        border-color: @color;
    }
    &.risk-tool__button {
        border-color: @color;
        color: @color-dark;
        &--active {
            background-color: @color;
            // color: #FFF;
            .risk-tool__icon-container {
                background-color: rgba(255,255,255,.5);
             }
        }
    }
    .content-panel__icon-box {
        background-color: rgba(255,255,255,.3)
    }
    .content-panel__icon, .slider-panel__icon {
        color: @color;
    }
    .cta-panel__button {
        background: none;
        border: 2px solid;
        color: inherit;
        &:hover {
            background-color: #FFF;
            border-color: #FFF;
            color: @color-dark;
        }
    }
    &:after {
        background-color: @color;
    }
    .slider-panel__overlay {
        background-color: fade(@color, 90%);
    }
    &.featured-panel {
        border-color: @color;
        color: @color-dark;
    }
    .featured-panel__icon-container, .risk-tool__icon-container {
        background-color: @color;
    }
}