/* 1. GRID
----------------------------------------------- */

.row {
    width: auto;

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

[class*='column-'] {
    float: left;
    padding-right: @padding;
    position: relative;
    min-height: 1px;

    &:last-of-type {
        padding-right: 0;
    }
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.column-1 {
    width: calc(100% / 12);
}

.column-2 {
    width: (100% / 6);
}

.column-3 {
    width: (100% / 4);
}

.column-4 {
    width: (100% / 3);
}

.column-5 {
    width: (100% / 2.4);
}

.column-6 {
    width: (100% / 2);
}

.column-7 {
    width: (100% / 1.714285714285714);
}

.column-8 {
    width: (100% / 1.5);
}

.column-9 {
    width: (100% / 1.333333333333333);
}

.column-10 {
    width: (100% / 1.2);
}

.column-11 {
    width: (100% / 1.090909090909091);
}

.column-12 {
    width: 100%;
}

.column,
[class*='column-'] {
    @media @tablet {
        width: 100%;
        padding: 0;
    }
}

.column {
    &--margin {
        margin-bottom: 30px;
    }
}

.pull-6 {
    left: ~"calc(-100% / 12 * 6)";

    @media @desktop {
        left: ~"calc(-100% / 12 * 9)";
    }
}

.pull-9 {
    left: -(100% / 1.333333333333333);

}

.push-3 {
    left: ~"calc(100% / 12 * 3)";
}

.column-center-6 {
    left: 25%;

    @media @tablet {
        left: 0;
    }
}

.desktop-12 {
    @media @desktop {
        width: 100%;
    }
}

.desktop-4 {
    @media @desktop {
        .column-4;
    }
}

.tablet-12 {
    @media @tablet {
        .column-12;
    }
}

// @media @desktop {
//     .column-4 {width: 100%;}
//     .column-8 {width: 100%;}
// }

// @media @tablet {
//     .column-1{ width: 50%; }
//     .column-2{ width: 100%; }
//     .column-3{ width: 100%;    }
//     .column-4{ width: 100%; }
//     .column-5{ width: 100%; }
//     .column-6{ width: 100%;   }
//     .column-7 {width: 100%;}
//     .column-8 {width: 100%; .column-6 {width:100%;}}
//     .column-9 {width: 100%;}
//     .column-10 {width: 100%;}
//     .column-11 {width: 100%;}
//     .column-12 {width: 100%;}

//     .pull-9 {left:0;}
//     .push-3 {left:0;}

//     [class*='column-'] {
//         float: left;
//         padding-right: 0;
//     }
// }

.width-reset-left {
    width: ~"calc(50% + 2px)";
}

.width-reset-right {
    width: ~"calc(50% - 2px)";
}




/* 2. VARIABLES
----------------------------------------------- */

// @desktop-wide: ~"only screen and (max-width : 1200px)";
// @desktop: ~"only screen and (max-width : 992px)";
// @tablet: ~"only screen and (max-width : 768px)";
// @mobile: ~"only screen and (max-width : 480px)";

// @padding: 20px;
// @color-green: #8dc63f;
// @color-orange: #f47920;
// @color-purple: #902c8e;

// .transition {
//     transition: all .2s ease;
// }



/* 2. BODY & FONTS
----------------------------------------------- */

html,
body {
    height: 100%;
    margin: 0;
    font-family: "sofia-pro-soft", sans-serif;
    // color: #6e6e6e;
    color: @color-dark;
}

.wrapper {
    padding-bottom: 300px;
    position: relative;
    min-height: 100%;
    padding-top: 60px;

    @media @desktop {
        padding: 60px 0 0 0;
    }
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    &.relative {
        position: relative;
    }
}

.padding-top-bottom {
    padding: 10px 0;
}

.no-padding {
    padding: 0 !important;
}

@font-face {
    font-family: 'icomoon';
    src: url('/fonts/FC/icomoon.eot');
    src: url('/fonts/FC/icomoon.eot?#iefix') format('embedded-opentype'), url('/fonts/FC/icomoon.woff') format('woff'), url('/fonts/FC/icomoon.ttf') format('truetype'), url('/fonts/FC/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

b {
    font-weight: 700;
}



/* 3. GLOBAL
----------------------------------------------- */


.panel-link {
    border-bottom: 3px solid #d1d1d1;
    overflow: auto;
    margin-top: -3px;
    border-top: 3px solid #d1d1d1;
    .transition;

    &:first-child {
        margin-top: 0;
    }

    &:hover {
        background-color: #F6F6F6;
    }

    .panel-link-container {
        overflow: hidden;
        position: relative;
        height: 75px;
    }

    a {
        color: inherit;
        text-decoration: none;
        overflow: auto;
        display: block;
    }

    .icon,
    p {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .icon {
        float: left;
        margin-right: 20px;
        min-width: 55px;
        text-align: center;
    }

    p {
        color: #6e6e6e;
        padding: 0;
        margin: 0;
        font-size: 20px;
    }
}







/* 3. HEADER & NAV
----------------------------------------------- */

header {
    min-height: 160px;
    position: relative;
    overflow: auto;
    padding: 20px 0;

    .logo {
        width: 120px;
        float: left;
        margin: 0 30px 0 0;
        .transition;

        &:hover {
            opacity: 0.8;
        }

        @media @desktop-wide {
            float: none;
            display: block;
            margin: auto;
        }
    }

    span.intro-text {
        color: #231f20;
        font-size: 20px;
        line-height: 24px;
        position: absolute;

        @media @desktop-wide {
            margin-top: 10px;
            display: block;
            text-align: center;
            position: relative;

            br {
                display: none;
            }
        }
    }

    a.contact-button {
        position: absolute;
        top: 0;
        right: 20px;
        background-color: @color-green;
        // color: #FFF;
        color: @color-dark;
        text-decoration: none;
        padding: 10px 65px 10px 10px;
        overflow: hidden;
        .transition;

        &:before, &:after {
            position: absolute;
            
            top: 12px;
            .transition;
        }

        &:before {
            content: '\f0e0';
            font-family: fontAwesome;
            transform: translateY(-180%);
            right: 16px;
        }

        &:after {
            content: "\e900";
            font-family: 'icomoon';
            right: 10px;
        }

        &:hover {
            background-color: lighten(@color-green, 10%);

            &:before {
                transform: translateY(0);
            }

            &:after {
                transform: translateY(180%);
            }
        }

        // @media @desktop-wide {
        //     left: 20px;
        //     right: auto;
        // }

        @media @mobile {
            display: none;
        }
    }

    nav {
        /*text-transform: uppercase;*/

        ul {
            margin: 0;
            padding: 100px 0 0 0;
            list-style-type: none;

            @media @desktop-min {
                display: flex;
                justify-content: space-between;
            }

            @media @desktop-wide {
                padding: 0;
            }

            li {
                display: inline-block;
                margin: 0 20px 0 0;
                padding: 0 20px 0 0;
                border-right: 2px solid #d1d1d1;
                position: relative;

                &:last-child {
                    border-right: 0;
                    padding-right: 0;
                    margin-right: 0;

                    a {
                        &:after {
                            width: 100%;
                        }
                    }
                }

                a {
                    text-decoration: none;
                    color: #231f20;
                    background-image: linear-gradient(#D7D7D7, #D7D7D7);
                    background-position: 0% 100%;
                    background-repeat: no-repeat;
                    background-size: 0% 2px;
                    transition: background-size .3s;
                    padding-bottom: 2px;

                    // &:after {
                    //     position: absolute;
                    //     bottom: -7px;
                    //     left: 0;
                    //     content: '';
                    //     background-color: #D7D7D7;
                    //     height: 3px;
                    //     width: ~"calc(100% - 20px)";
                    //     transform: scaleX(0);
                    //     .transition;
                    // }

                    &:hover {
                        // padding-bottom: 10px;
                        background-size: 100% 2px;

                        // &:after {
                        //     transform: scaleX(1);
                        // }
                    }

                    &.active {
                        background-image: linear-gradient(#f47920, #f47920);
                        background-size: 100% 2px;
                        // &:after {
                        //     background-color: #f47920;
                        //     transform: scale(1, 1);
                        // }
                    }
                }
            }
        }

        @media @desktop-wide {
            margin: 20px 0 0 0;
            text-align: center;
        }

        @media @desktop {
            position: fixed;
            top: 59px;
            left: 0;
            z-index: 998;
            width: 100%;
            pointer-events: none;
            margin: 0;

            ul {
                -webkit-transform: translate(0, -670px);
                transform: translate(0, -670px);
                height: auto;
                background-color: #333238;
                border-bottom: 4px solid #707586;
                padding: 30px;
                overflow: auto;
                pointer-events: all;
                margin: 0 -20px;
                display: block;
                .transition;

                li {
                    width: 100%;
                    float: left;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    line-height: 25px;
                    border-right: 0;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                    &:last-child {
                        border-bottom: 0;
                    }

                    a {
                        width: 100%;
                        text-align: center;
                        display: block;
                        padding: 15px 0;
                        color: #FFF;

                        &:hover {
                            padding: 15px 0;

                            &:after {
                                display: none;
                            }
                        }

                        &.active {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }

            .slide {
                -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
            }
        }
    }
}

.searchcontainer form {
    margin-top: 15px;
}

// C2 copied from sandyford
.searchcontainer form input {
    height: 25px;
    float: left;
    border: none;
    width: 180px;
    margin: 0;
    background: #ebebeb;
    box-shadow: none;
    color: #3c3c3b;
}

.searchcontainer form input.submitsearch {
    background: url(/img/searchbutton.png);
    text-transform: capitalize;
    width: 26px;
    font-size: 25px;
    border: none;
    color: transparent;
}

a#pull {
    display: none;
    width: 59px;
    height: 59px;
    z-index: 999;
    .transition;

    span.line,
    span.line:before,
    span.line:after {
        height: 3px;
        width: 23px;
        background: #FFF;
        display: block;
        border-radius: 23px;
        position: absolute;
        top: 27px;
        left: 18px;
        content: '';
        .transition;
    }

    span.line:before {
        position: absolute;
        top: -8px;
        left: 0;
    }

    span.line:after {
        position: absolute;
        top: 8px;
        left: 0;
    }

    &.active {
        // background-color: transparent;

        span.line {
            background-color: transparent;

            &:before {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                top: 0;
            }

            &:after {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                position: absolute;
                top: 0;
            }
        }
    }

    @media @desktop {
        display: block;
        position: fixed;
        right: 0px;
        top: 0px;
        background-color: rgba(50, 49, 55, .94);
        pointer-events: auto;
    }
}







/* 3. HOMEPAGE
----------------------------------------------- */

section.homepage {
    /*margin-bottom: -200px;*/

    @media @desktop {
        margin: 0;
    }

    .intro-panel {
        background-color: #e2e2e2;

        // min-height: 500px;
        .intro-message {
            padding: 50px 0;
            position: relative;

            @media @desktop-wide {
                padding: 50px 80px 50px 20px;
            }

            p {
                margin: 0;
                max-width: 685px;
            }

            p,
            h1,
            h2,
            h3 {
                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

        }

        .coloured-panels {
            @media @desktop-wide {
                padding: 0 0 0 10px;
            }

            [class*='column-'] {
                padding-right: 10px;
            }

            a {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    .find-col {
        @media @desktop {
            width: 100%;
        }
    }

    .coloured-links-col {
        @media @desktop {
            width: 100%;
        }
    }

    .twitter-col {
        @media @desktop-wide {
            padding-left: 10px;
            padding-right: 10px;
        }

        @media @desktop {
            width: 50%;
        }

        @media @mobile {
            width: 100%;
        }
    }

    .feedback-col {
        @media @desktop-wide {
            padding-right: 10px;
        }

        @media @desktop {
            width: 100%;
            padding-left: 10px;
        }

        @media @mobile {
            width: 100%;
            padding-left: 10px;
            margin-top: 20px;
        }
    }

    .links-col {
        h2 {
            // padding-left:20px;
            color: @color-purple;
            margin: 10px 0;
            font-size: 22px;
        }

        @media @desktop {
            width: 100%;
        }
    }

    .panel {
        padding: 20px;
        color: #FFF;
        min-height: 410px;
        margin-bottom: 10px;
        position: relative;
        background-size: cover;

        &.overlay {
            position: relative;

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.8;
                .transition;
            }
        }

        &:hover {
            &:before {
                opacity: 0.9;
                backdrop-filter: blur(4px);
            }
        }

        &.orange {
            background-color: @color-orange;

            &:before {
                background-color: @color-orange;
            }
        }

        &.green {
            background-color: @color-green;

            &:before {
                background-color: @color-green;
            }
        }

        &.purple {
            background-color: @color-purple;

            &:before {
                background-color: @color-purple;
            }
        }

        &.half-height {
            min-height: 210px;
            float: left;
            width: ~"calc(50% - 10px)";
            margin-right: 10px;
            position: relative;

            @media @mobile {
                width: ~"calc(100% - 10px)";
            }

            &:nth-child(3) {
                float: right;
            }

            a {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        &.feedback {
            background-color: @color-green;
            margin: 0;
            padding: 60px 20px 20px;
            min-height: auto;
            color: #FFF;
            .transition;

            &:hover {
                background-color: lighten(@color-green, 7%);
            }

            &:after {
                content: "\e90e";
                position: absolute;
                top: 20px;
                left: 20px;
                color: #FFF;
                font-family: icomoon;
                font-size: 52px;
                .transition;
            }

            a.panel-link {
                font-size: 16px;
                border: 0;
                color: inherit;
            }

            a.panel-link-whole {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &.find-venue {
            padding: 40px;
            min-height: 430px;

            &:after {
                content: "\e90d";
                font-family: 'icomoon';
                position: absolute;
                top: 40px;
                right: 40px;
                color: #FFF;
                font-size: 175px;

                @media @tablet {
                    display: none;
                }
            }

            p {
                font-size: 20px;
                line-height: 24px;
                max-width: 300px;
            }

            input#postcode {
                background: none;
                border: 3px solid #FFF;
                color: #FFF;
                padding: 15px;
                min-width: 300px;
                .transition;

                &::-webkit-input-placeholder {
                    color: #FFF;
                }

                &:focus {
                    background-color: #FFF;
                    outline: 0;
                    color: #6E6E6E;
                    font-weight: 600;

                    &::-webkit-input-placeholder {
                        color: #ccc;
                    }
                }

                @media @mobile {
                    min-width: auto;
                    width: 100%;
                }
            }

            .submit-container {
                position: relative;
                display: inline-block;
                margin-top: 15px;

                &:after {
                    content: "\e900";
                    font-family: icomoon;
                    position: absolute;
                    right: 15px;
                    top: 15px;
                }
            }

            input[type=submit] {
                display: block;
                min-width: 300px;
                background-color: #8dc63f;
                border: 0;
                padding: 15px;
                color: #231f20;
                text-align: left;
                font-weight: 600;
                font-size: 16px;
                position: relative;
                .transition;

                &:hover {
                    background-color: lighten(#8dc63f, 10%)
                }

                @media @mobile {
                    min-width: auto;
                    width: 100%;
                    padding-right: 55px;
                }
            }
        }

        h1.large {
            font-size: 47px;
            font-weight: 500;
            margin: 0;
        }

        h1 {
            font-size: 28px;
            font-weight: 500;
            margin: 0;
        }

        h2 {
            font-size: 24px;
            font-weight: 500;
        }

        p {
            font-weight: 300;
            font-size: 16px;
        }
    }

    .panel * {
        position: relative;
    }

    a.twitter-link {
        text-decoration: none;
    }

    .twitter {
        background-color: #DCDCDC;
        padding: 20px;
        color: #000;
        margin-top: 10px;
        position: relative;
        .transition;

        &:after {
            content: "\e915";
            font-family: 'icomoon';
            position: absolute;
            top: 16px;
            right: 20px;
            font-size: 24px;
            pointer-events: none;
            color: @color-orange;
        }

        &:hover {
            background-color: @color-purple;
            color: #FFF;

            &:after {
                color: #FFF;
            }
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        .heading {
            background-color: #6e6e6e;
            color: #FFF;
            padding: 20px;
            margin: -20px -20px 20px -20px;

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        .tweet {
            font-size: 16px;
            font-weight: 300;
            border-bottom: 2px solid #FFF;
            padding-bottom: 10px;
            margin-bottom: 10px;

            &:nth-child(2) {
                border: 0;
                margin: 0;
            }

            p {
                margin: 0 0 5px;
            }

            a {
                color: #6E6E6E;
            }

            .date,
            .time {
                font-size: 14px;
                text-transform: uppercase;
                margin-right: 5px;
            }
        }
    }

    .small-panel {
        padding: 20px;
        min-height: 175px;
        position: relative;

        @media @tablet {
            min-height: 200px;
        }

        @media @desktop {
            width: 33.333333%;
        }

        @media @tablet {
            width: 100%;
            border-bottom: 2px solid @color-green;
            min-height: auto;

            &:after {
                display: none;
            }

            &:before {
                display: none;
            }

            &:last-child {
                border: 0;
            }
        }

        h3 {
            font-size: 18px;
            margin-top: 0;
            font-weight: 500;
        }

        p {
            font-size: 16px;
            font-weight: 300;
            line-height: 19px;
        }

        a.panel-link {
            font-size: 16px;
            border: 0;
            color: inherit;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 20px;
            width: 3px;
            height: ~"calc(100% - 40px)";
            background-color: @color-green;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}





/* 3. CONTENT
----------------------------------------------- */


.faq:last-child {
    margin-bottom: 40px;
}

.faq {
    min-height: initial;
    border: 0 !important;

    p {
        font-size: 16px !important;
        font-weight: 800;
    }

    .FAQAnswerCaption {
        padding: 20px;
        background-color: #f6f6f6;
    }

    .FAQAnswerFull {
        display: none;
        background-color: #fef2eb;
        padding: 20px;

        p {
            margin: 0;
        }
    }

    .faqTitleWrapper {
        h4 {
            min-height: inherit;
            padding: 0 0 0 20px;
            background-image: none;
            margin-bottom: 0;
            background-color: @color-purple;
        }

        a {
            color: #FFF;
            padding: 15px 0;
            width: 100%;
            display: block;
            text-decoration: none;
        }

        a:after {
            content: "\e5cf";
            font-family: 'icomoon';
            float: right;
            top: -4px;
            right: 20px;
            position: relative;
            font-size: 28px;
            .transition;
        }
    }

    .active {
        h4 {
            background-color: #F48132;
        }

        a:after {
            transform: scaleY(-1);
            -webkit-transform: scaleY(-1);
            -moz-transform: scaleY(-1);
            -ms-transform: scaleY(-1);
        }
    }
}


section.content-header {
    background-color: @color-purple;
    color: #FFF;
    margin-bottom: 20px;
    position: relative;
    padding: 0 20px;

    &:after {
        background: linear-gradient(-45deg, #fff 16px, transparent 0), linear-gradient(45deg, #fff 16px, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: 27px 34px;
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 34px;
    }

    .content-header-container {
        position: relative;
        max-width: 1200px;
        position: relative;
        margin: auto;
        min-height: 230px;
        padding: 20px;

        p {
            max-width: 685px;

            &:last-child {
                margin-bottom: 40px;
            }
        }
    }

    span.breadcrumb {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            // @media @desktop-wide {
            //     padding-left :20px;
            // }
            li {
                display: inline-block;
                margin: 0 30px 0 0;
                position: relative;

                &:after {
                    content: '>';
                    position: absolute;
                    right: -20px;
                    top: 0;
                }

                &:last-child {
                    text-decoration: underline;

                    &:after {
                        display: none;
                    }
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }

    h1 {


        position: absolute;
        bottom: 40px;

        /*left: 25%;*/
        /*text-transform: uppercase;*/
        font-size: 36px;
        margin: 0;
        font-weight: 500;

        @media @desktop {
            left: 0;
        }

        @media @mobile {
            font-size: 28px;
        }
    }

    &--homepage {
        padding: 30px 0 20px;
        margin-bottom: 50px;

        h1 {
            position: relative;
            bottom: auto;
        }

        .content-header-container {
            padding: 20px;
        }
    }
}

.content {
    padding-bottom: 1em;

    @media @desktop {
        padding: 0;
    }
}

.content-main {
    @media @desktop {
        // width: 75%;
        padding: 0 20px;
        width: 100%;
        left: 0;
    }

    @media @tablet {
        width: 100%;
        padding: 20px;
    }

    img.main-image {
        max-width: 100%;
        object-fit: cover;
    }

    img {
        max-width: 100%;
    }

    p {
        line-height: 28px;
    }

    ul {
        list-style-type: none;

        li {
            margin-bottom: 15px;
            line-height: 28px;
            position: relative;

            &:before {
                content: '•';
                position: absolute;
                left: -23px;
                top: -3px;
                color: @color-orange;
                font-size: 19px;
            }
        }
    }

    a {
        color: @color-purple;
        font-weight: 600;
    }

    h2 {
        color: @color-purple;
        font-size: 32px;
    }

    h3 {
        font-size: 24px;
    }
}

.find-a-venue {
    @media @desktop {
        width: 100%;
    }
}

.left-nav {
    margin-bottom: 30px;
    padding-right: 20px !important;

    @media @desktop-wide {
        padding-left: 10px;
    }

    @media @desktop {
        width: 100%;
        left: 0;
        padding: 0 20px;
        margin-top: 50px;
    }

    a.side-heading {
        text-decoration: none;
        color: inherit;
        font-size: 24px;
        /*text-transform: uppercase;*/
        color: #231f20;
        border-bottom: 2px solid #d1d1d1;
        padding: 0 0 18px 18px;
        display: block;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            font-size: 18px;
            color: #6e6e6e;
            border-bottom: 2px solid #d1d1d1;
            margin: 0;

            a {
                color: inherit;
                padding: 15px 18px;
                display: block;
                text-decoration: none;
                .transition;

                &.active {
                    background-color: @color-purple;
                    color: #FFF;
                    margin-top: -2px;

                    &:hover {
                        background-color: lighten(@color-purple, 10%)
                    }
                }

                &:hover {
                    background-color: #F6F6F6;
                }
            }

            ul {
                // padding-left: 20px;
                border-top: 1px solid #d1d1d1;

                li {
                    border: 0;

                    a {
                        padding: 10px 18px;
                        color: @color-purple;

                        &.active {
                            text-decoration: underline;
                            color: @color-purple;
                            background: none;

                            &:hover {
                                background: none;
                                padding-left: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.right-nav {
    @media @desktop {
        width: 100%;
        margin: 100px 0 50px 0;
        padding: 0 20px;
    }

    .panel-link {
        @media @desktop {
            width: ~"calc(50% - 20px)";
            float: left;
            margin-right: 20px;

            &:first-child {
                margin-top: -3px;
            }
        }

        @media @tablet {
            width: ~"calc(100% - 20px)";
        }
    }
}

.childList {
    overflow: auto;
    border-top: 3px solid #d1d1d1;
    border-bottom: 3px solid #d1d1d1;
    margin-top: -3px;
    padding: 15px 0;

    h4 {
        margin: 0 0 10px;
        font-size: 14px;
        font-weight: 500;
    }

    p {
        font-size: 14px;
        font-weight: 300;
        margin-top: 0;
    }

    img {
        cursor: pointer;

        &.noPopup {
            cursor: default;
        }
    }


    .shape {
        padding-left: 20px;
    }

    .quantity,
    .Bag-code {
        display: inline-block;
        margin: -5px 0 0 5px;
        padding: 6px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 300;
    }

    .quantity {
        padding-left: 0;
    }

    span.product-text {
        font-size: 14px;
    }

    .Bag-code {
        background: @color-purple;
        color: #FFF;

        &.STN {
            background: #fff200;
            color: #000;
        }

        &.HALO {
            background: #C180DF;
        }

        &.LAR {
            background: #ac7d07;
            color: #000;
        }

        &.TRI {
            background: #a0248e;
            color: #fff;
        }

        &.BLK {
            background: #000;
            color: #fff;
        }

        &.LATEX {
            background: #0c99b4;
            // color: #fff;
            color: #000;
        }

        &.FEMALE {
            background: #f49ac1;
            color: #000;
        }

        &.VAR {
            background: #ed1c24;
            color: #000;
        }

        &.UNI {
            background: #ed1c24;
            color: #FFF;
        }

        &.LUB1 {
            background: #00aeef;
            color: #fff;
        }

        &.LUB2 {
            background: #8f9194;
            color: #fff;
        }
    }
}


/* VENUE SEARCH - GOOGLE MAP
----------------------------------------------- */


#mapPanel {
    width: 100%;
    height: 400px;
}

.results {
    margin: 0 -15px 0 0;
}

.searchStrip {
    background: #F1F1F1;
    padding: 20px;
    margin-bottom: 15px;
    overflow: auto;

    .postcodeSearch {
        width: 50%;
        float: left;

        @media @mobile {
            width: 100%;
        }
    }

    .findLocation {
        width: 50%;
        float: right;

        input {
            float: right;
            background: @color-green;
            border: 0;
            padding: 5px 10px;
            color: #FFF;

            @media @mobile {
                width: 100%;
                margin-top: 10px;
            }
        }

        @media @mobile {
            width: 100%;
        }
    }

    input#postcode {
        width: 67%;
        border: 0;
        padding: 5px 10px;

        @media @tablet {
            width: 50%;
        }
    }

    input#search {
        background: url(/img/search.png) #4cd0ff no-repeat 57px;
        color: #FFF;
        border: 0;
        padding: 5px 30px 5px 10px;
    }
}

.col-sm-6 {
    width: 50%;
    float: left;

    @media @tablet {
        width: 100%;
    }
}

.clinic {
    padding-right: 15px;
    min-height: 330px;

    .clinicName {
        background-color: #F1F1F1;
        margin: 15px 0;
        padding: 15px;

        a {
            float: right;
        }
    }

    h2 {
        line-height: 1.5em;
        font-size: 1.1em;
        margin: 0;
        font-weight: 600;
        width: 70%;
        float: left;
        text-transform: none;
    }

    h4 {
        margin: 10px 0 0 0;
        line-height: 35px;
        font-size: 1em;
        font-weight: 600;
        font-style: italic;
    }

    h5 {
        font-weight: 600;
        text-decoration: underline;
        /*text-transform: uppercase;*/
        margin: 0 0 10px;
        font-size: 0.9em;
    }

    p {
        line-height: 1.4em;
        font-weight: 300;
    }

    @media @tablet {
        min-height: 311px;
    }

    @media @mobile {
        min-height: initial;
    }
}

.clinicSmall {
    min-height: 265px;

    @media @desktop-wide {
        min-height: 305px;
    }

    @media @desktop {
        min-height: 305px;
    }

    @media @tablet {
        min-height: initial;
    }

    @media @mobile {
        min-height: initial;
    }
}

#limitedHolder,
.limitedHolder {
    padding: 30px;
    border: 1px solid #E4E4E4;
    margin: 20px 0;
    background-color: #F9F9F9;
    display: none;

    h3 {
        margin-top: 0;
    }

    input[type=button] {
        background: #8dc63f;
        border: 0;
        padding: 5px 10px;
        color: #FFF;
    }
}





/* 3. RELATED LINKS
----------------------------------------------- */

section.related-links {
    position: absolute;
    bottom: 243px;
    min-height: 150px;
    background-color: #eaeaea;
    width: 100%;

    @media @desktop {
        position: relative;
        bottom: 0;
        padding: 20px;
    }

    h2 {
        color: @color-purple;
        font-size: 20px;
    }

    .column-3 {
        @media @desktop {
            width: 50%;
        }

        @media @tablet {
            width: 100%;
        }
    }

    .panel-link {
        margin: 20px 0;
        border-color: #FFF;
        background: linear-gradient(to bottom, #eaeaea 50%, #fff 50%);
        background-size: 100% 200%;

        @media @desktop {
            margin: -3px 0 0 0;
        }

        p {
            color: #231f20;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        .icon {
            margin: 0 20px 0 30px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover {
            background-position: 0% 100%;
        }
    }
}





/* 3. FOOTER
----------------------------------------------- */

footer {
    background-color: @color-purple;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    min-height: 200px;
    color: #FFF;
    padding: 20px 0;
    font-size: 14px;

    a {
        color: #FFF;
    }

    @media @desktop {
        position: relative;
        padding: 20px 10px;
        text-align: center;
    }

    .logo-column {
        @media @desktop {
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .logo {
        max-width: 120px;
        float: left;
        margin: 0 30px 0 0;

        @media @desktop {
            display: block;
            float: none;
            margin: 0 auto 10px;
        }
    }

    h3 {
        text-decoration: underline;
        /*text-transform: uppercase;*/
        margin-top: 0;
        font-size: 16px;
    }

    p {
        font-size: 14px;
        margin-top: 0;
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            margin: 0;

            a {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}





/* 3.  FORMS STYLE OVERRIDE
----------------------------------------------- */



.contourFieldSet {
    padding: 0 !important;

    input[type=text],
    textarea,
    select {
        padding: 10px !important;
        border: 1px solid #DDD !important;
        width: 100% !important;
        max-width: 400px;
        font-family: "sofia-pro-soft", sans-serif !important;
    }
}

.btn,
&.primary {
    background-color: @color-orange;
    padding: 15px 20px;
    border: 0;
    color: #FFF;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
}



/* 3.  CHOICE CARD
----------------------------------------------- */

body.choice-card {
    display: flex;
    flex-direction: column;
}

.choice-card__wrapper {
    padding: 50px 0;
    flex: 1;
    min-height: 600px;

    @media @tablet {
        min-height: 1000px
    }

    &:before,
    &:after {
        background: linear-gradient(-45deg, @color-orange 24px, transparent 0), linear-gradient(45deg, @color-orange 24px, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: 34px 34px;
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 32px;
    }

    &:before {
        transform: scaleY(-1);
        bottom: auto;
        top: 0;
    }

    .container {
        padding: 0 20px;
    }
}

.choice-card__col {
    margin-bottom: 30px;

    &:last-of-type {
        padding-right: 20px;

        @media @tablet {
            padding-right: 0;
        }
    }
}

.choice-card__bg {
    overflow: auto;
    border-radius: 35px 0 0 35px;
}

.topHalf {
    // height: 50%;
    // background: #922790;
    // color: white;
    // padding: 20px;
    text-align: left;
    max-width: 800px;
    margin-bottom: 30px;

    @media print {
        display: none;
    }

    p,
    li {
        font-size: 1.3rem;

        @media @mobile {
            font-size: 1rem;
        }

        a {
            color: @color-orange;
            text-decoration: underline;
            word-break: break-all;
        }
    }
}

.choice-card__top-half {
    background-color: @color-orange;
    color: #FFF;
    padding: 5px 0;

    ul li a,
    p a {
        color: #FFF;
    }

    ul {
        padding-left: 25px;
    }

    .container {
        padding: 0 20px;
    }
}

.lowerHalf {
    color: black;
    padding: 20px 0;

    .column-3 {
        @media @tablet {
            width: 25%;
        }
    }

    .checkboxBG {
        position: relative;
        min-height: 65px;

        @media print {
            -webkit-print-color-adjust: exact;
        }

        label {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            cursor: pointer;
        }
    }

    p.label {
        margin: 0;
        padding-left: 76px;
        pointer-events: none;
        font-size: 2.8rem;
        line-height: 61px;

        @media @desktop {
            font-size: 2rem;
        }

        @media @mobile {
            font-size: 1.4rem;
        }

        @media print {
            font-size: 1.5rem;
        }
    }

    .standard {
        background: #fff200;
    }

    .large {
        background: #ac7d07;
    }

    .female {
        background: #f49ac1;
    }

    .halo {
        background: #922790;
        color: #FFF;
    }

    .trim {
        background: #a0248e;
        color: #FFF;
    }

    .black {
        background: #000;
        color: #FFF;
    }

    .latexfree {
        background: #0c99b4;
    }

    .variety {
        background: #ed1c24;
    }

    .opaque {
        background: #8f9194;
    }

    .clear {
        background: #00aeef;
    }

    input[type="checkbox"] {
        display: none;
    }

    input[type="checkbox"]+label span {
        display: inline-block;
        width: 50px;
        height: 50px;
        background: #FFF;
        border: 2px solid #000;
        cursor: pointer;
        position: absolute;
        border-radius: 50%;
        left: 10px;
        top: 50%;
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    input[type="checkbox"]:checked+label span {
        &:before {
            content: "\e5ca";
            font-family: 'icomoon';
            position: absolute;
            top: 0;
            left: 0;
            color: #000;
            font-size: 35px;
            text-align: center;
            width: 100%;
            line-height: 50px;
            font-weight: 700;
        }
    }
}








/* 13. MODAL
----------------------------------------------- */





.tinymodal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    overflow: auto;
}

.tinymodal-window {
    position: absolute;
    max-width: 600px !important;
    width: 100% !important;
    top: 20%;
    left: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 60px;
    border-radius: 20px;
}

.tinymodal-title {
    background: #f47920;
    color: #FFF;
    padding: 30px;
    font-size: 22px;
    position: relative;
    border-radius: 7px 7px 0 0;
}

.tinymodal-close {
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -.5em;
    font-size: 150%;
    line-height: 1;
    cursor: pointer
}

.tinymodal-content {
    padding: 0;
    background: #fff;
    border-radius: 0 0 7px 7px;
    max-height: 550px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        margin: 0;
        line-height: 28px;
    }

    a.button {
        display: inline-block;
        margin: 20px 20px 0 0;
        padding: 15px 20px;
        border-radius: 5px;
        color: #6F717D;
        font-weight: 600;
        text-decoration: none;
        border: 2px solid #eee;
        .transition;

        &:hover {
            background: @color-green;
            color: #FFF;
            border-color: @color-green;
        }
    }
}


/*
    FC 2021 specific
*/
.FCGrid {
    section.content-header {

        h1 {
            left: unset;
            text-transform: unset;
        }
    }
}

.FC-blockNav {
    [class*=column-]:last-of-type {
        padding-right: 20px;
    }

    .panel-link {
        padding: 2em;
        margin-bottom: 1em;
        background-color: #902c8e;
        border: none;
        color: #fff;
        min-height: 110px;

        align-content {
            padding-top: 1em;
            padding-bottom: 1em;
        }
    }

    .content-panel {
        // margin-top: 0.5em;
        // min-height: 360px;

        a.content-panel__link {
            display: none;
        }

        .content-panel__link-container {
            margin-top: 0;
            border-top: none;
            padding-top: 0;
        }
    }
}


.products-list {
    .childList {
        overflow: auto;
        border: none;
        border-bottom: 1px solid #eaeaea;
        margin-top: -3px;
        padding: 1em 0.3em;
        text-align: center;
        /* max-height: 460px; */
        min-height: 420px;

        h4 {
            font-size: 1.5em;
        }
    }

    &.product-sizematters {
        .childList {
            min-height: unset;
        }
    }
}

.products-listAlt {

    .childList {
        overflow: auto;
        border: none;
        border-bottom: 1px solid #eaeaea;
        margin-top: -3px;
        padding: 0 0;
        text-align: center;
        min-height: 420px;
        background: #f6f0f7;
        margin: 1px 1px 5px 1px;

        /*width: 33%;*/
        .product-img {
            background-color: #FFF;
        }

        .product-text {
            padding: 1em 0.3em;
        }
    }

    h4 {
        font-size: 1.5em;
    }
}

.product__image {
    width: auto;
}

// .column .products-list .childList img,
// .column .products-listAlt .childList img {
//     width: auto;
// }

/* uforms override for stock form */
#contour_form_eaf0cc3dd08f45149481a64f7e9b2327 {
    textarea {
        height: 100px;
    }
}

.contourField {
    margin-bottom: 30px;

    &.checkbox {
        display: flex;
        flex-wrap: wrap;

        label {
            flex: 0 0 100%;
            margin-bottom: 5px;
        }

        .help-block {
            order: 1;
            margin-left: 7px;
        }
    }
}

fieldset#fs-6d3349a4-3a85-4fc4-ccc2-8450c80f8053 input.text,
fieldset#fs-575d6aee-e230-453c-bd31-946bc3521f01 input.text,
fieldset#fs-fefeb086-5968-4567-f1b3-c9f94cc00cc4 input.text {
    background-color: #efefef;
    width: 100px !important;
}


/* 14. SHARED STYLES
----------------------------------------------- */
@import "../shared styles.less";
// NEW MARCH 2021
@import "modules/variables";
@import "modules/mixins";
@import (inline) "modules/font-awesome.css";
@import "modules/content";
@import "modules/accordion";
@import "modules/panels";
@import "modules/featured-panels";
@import "modules/owl-carousel";
@import "modules/slider";
@import "modules/document-list";
@import "modules/sandyford-header";
@import "modules/colours";