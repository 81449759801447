.featured-panel__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @media @mobile {
        display: block;
    }
}

.featured-panel {
    flex: 1 1 ~"calc(25% - 20px)";
    margin: 0 10px 20px;
    background-color: #FFF;
    border: 10px solid #eee;
    padding: 20px;
    display: flex;
    align-items: center;
    color: @color-dark;
    position: relative;
    &:hover {
        background-color: #fafafa;
    }
}

.featured-panel__icon-container {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    background-color: #eee;
    border-radius: 50%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featured-panel__icon {
    font-size: 30px !important;
}

.featured-panel__title {
    margin: 0 0 10px;
}

.featured-panel__link {
    color: @color-dark;
}