// @desktop-wide: ~"only screen and (max-width : 1200px)";
// @desktop: ~"only screen and (max-width : 992px)";
// @tablet: ~"only screen and (max-width : 768px)";
// @mobile: ~"only screen and (max-width : 480px)";

.sandyford-header {
    background: #323137;
    background: rgba(50, 49, 55, 0.94);
    position: fixed;
    width: 100%;
    z-index: 11;
    height: 59px;
    top: 0;

    .label--hidden {
        display: none;
    }

    @media @mobile {
        position: fixed;
        width: 100%;
    }

    .content {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media @desktop-wide {
            padding: 0 20px;
            width: auto;
        }
    }

    .column-6 {
        @media @tablet {
            width: 50%;
        }
    }

    .logo {
        margin-right: auto;

        img {
            border: none;
            padding: 10px 0;
            height: auto;
        }
    }
    // a.nhs-link {
    //     @media @mobile {
    //         display: none;
    //     }
    // }
    a.quickExit {
        background-color: @color-orange;
        // background: url("/img/exit.png") no-repeat #e74e1b 92%;
        // float: right;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 6px 60px 6px 10px;
        padding: 6px 10px;
        min-width: 152px;
        top: 15px;
        position: relative;
        color: @color-dark;
        text-decoration: none;
        order: 10;
        margin-left: 10px;
        // background-color: @color-orange  !important;
        .transition;

        &:after {
            content: '\f08b';
            font-family: fontawesome;
        }

        @media @desktop {
            margin-right: 60px;
        }

        @media @mobile {
            display: none;
        }

        &:hover {
            text-decoration: none;
            background-color: lighten(@color-orange, 10%);
            // background: url("/img/exit.png") no-repeat lighten(#e74e1b, 5%) 92%;
            // padding: 6px 50px 6px 20px;
        }
    }

    .searchcontainer {
        @media @tablet {
            display: none;
        }

        input {
            min-height: 33px;
            padding: 0 10px;

            &.submitsearch {
                background-repeat: no-repeat;
                background-position: center;
                background-color: #4DD1FF;
            }
        }
    }
}