.doclist {
    .extensionIcon {
      position: relative;
  
      &:before {
        font-family: 'fontAwesome';
        font-size: 17px;
        position: absolute;
        color: @color-green;
      }
    }
  
    .default:before {
      content: '\f016';
    }
  
    .pdf:before {
      content: '\f1c1';
    }
  
    .doc:before {
      content: '\f1c2';
    }
  
    .excel:before {
      content: '\f1c3';
    }
  
    .image:before {
      content: '\f1c5';
    }
  
    .folder:before {
      content: '\f114';
    }

    .pdf {
        position: relative;
    
        &:before {
          font-family: 'fontAwesome';
          content: '\f1c1';
          font-size: 17px;
          position: absolute;
          color: @color-green;
        }
      }
    
      .docx {
        position: relative;
    
        &:before {
          font-family: 'fontAwesome';
          content: '\f1c2 ';
          font-size: 17px;
          position: absolute;
          color: @color-green;
        }
      }
    
      .doc {
        position: relative;
    
        &:before {
          font-family: 'fontAwesome';
          content: '\f1c2 ';
          font-size: 17px;
          position: absolute;
          color: @color-green;
        }
      }
    
      h3 {
        background-color: @color-green;
        /*text-transform: uppercase;*/
        text-transform: capitalize;
        color: @color-dark;
        font-weight: 600;
        font-size: 1em;
        line-height: 40px;
        padding: 0 20px;
        margin: 0;
      }
    
      ul {
        list-style-type: none;
        padding: 0;
        background-color: #FFF;
    
        li {
          line-height: 37px;
          border-bottom: 2px solid #c8f1e5;
          font-size: 1em;
    
          &:hover {
            background-color: #F1F1F1;
          }
    
          &.active:hover {
            background: none;
          }
    
          a {
            color: @color-dark;
            font-weight: 400;
            display: block;
            font-size: 1em;
            padding-left: 30px;
            text-decoration: none;
          }
    
          &:last-child {
            border-bottom: 0;
          }
        }
      }
  
  }