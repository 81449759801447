// .column {
//     display: flex;
//     flex-wrap: wrap;
//     flex-direction: column;
//     p {
//         margin: 0 0 30px;
//     }
// }

.content-panel {
    background-color: #FFF;
    border: 2px solid #eee;
    padding: 20px;
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    height: 100%;
    &:hover:not(.panel--colour) {
        background-color: #fafafa;
    }
    &:not(:only-of-type) {
        margin-bottom: 20px;
    }
    p {
        font-size: 1.6rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.content-panel__title {
    margin: 0 0 15px;
    // font-weight: 400;
    font-size: 25px;
}

.content-panel__text {
    flex: 1 1 auto;
    a {
        color: inherit;
        z-index: 11;
        position: relative; 
        
        &:before {
            content: '\f08e';
            font-family: fontAwesome;
            margin: 0 5px;
            display: inline-block;
            font-size: 11px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.5;
    }
}

.content-panel__icon-box {
    background-color: rgba(0,0,0,.05);
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.content-panel__icon-container {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-panel__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-panel__icon {
    font-size: 40px !important;
}

.content-panel__link-container {
    // display: flex;
    // flex-wrap: wrap;
    margin-top: 20px;
    border-top: 1px solid rgba(255,255,255,.3);
    padding-top: 10px;
    // align-items: flex-end;
    // justify-content: space-between;
}

.content-panel__link {
    margin-top: 10px;
    display: block;
    color: inherit;
    // flex: 1 1 200px;
    // &:not(:only-of-type) {
    //     .slider-panel__button;
    //     flex: 0 1 ~"calc(50% - 10px)";
    // }
    &:hover {
        color: inherit;
    }
    &:before {
        content: '\f054';
        font-family: fontAwesome;
        margin: 0 5px;
        display: inline-block;
        font-size: 11px;
    }
}

.cta-panel {
    background-color: rgb(243, 243, 243);
    padding: 40px;
    // height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    &:not(:only-of-type) {
        margin-bottom: 20px;
    }
    @media @mobile {
        padding: 30px;
    }
    p, ul {
        margin: 20px 0;
    }
    p , li {
        font-size: 1.6rem;
        &:last-of-type {
            margin: 0;
        }
    }
    a:not(.cta-panel__button) {
        color: inherit !important;
        text-decoration: underline;
        &:before {
            content: '\f08e';
            font-family: fontAwesome;
            margin: 0 5px;
            display: inline-block;
            font-size: 11px;
        }
    }
}

#homepage {
    .cta-panel:not(.panel--colour) {
        background-color: #FFF;
    }
}

.cta-panel__title {
    margin-top: 0 !important;
    color: inherit !important;
}

.cta-panel__buttons {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 10px;
    margin-top: auto;
    &:empty {
        margin: 0;
    }
}

.cta-panel__button {
    // background-color: #FFF;
    border: 2px solid;
    color: @color-dark;
    padding: 10px 20px;
    text-decoration: none !important;
    margin: 15px 15px 0 0;
    border-radius: 4px;
    display: inline-block;
    .transition;
    &:hover {
        background-color: @color-dark;
        border-color: @color-dark;
        color: #FFF;
    }
}

#homepage {
    .col-sm-6 {
        .content-panel {
            .content-panel__link-container {
                justify-content: space-between;
                display: flex;
                flex-wrap: wrap;
            }
            .content-panel__link {
                // .slider-panel__button;
                flex: 0 1 ~"calc(50% - 10px)";
            }
        }
    }
}