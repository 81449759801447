@desktop-wide: ~"only screen and (max-width : 1200px)";
@desktop: ~"only screen and (max-width : 992px)";
@tablet: ~"only screen and (max-width : 768px)";
@mobile: ~"only screen and (max-width : 480px)";

@color-green: #8dc63f;
@color-orange: #f47920;
@color-purple: #902c8e;

.transition {
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}


/*  MODAL
----------------------------------------------- */


.tinymodal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    overflow: auto;
}

.tinymodal-window {
    position: absolute;
    max-width: 600px !important;
    width: 100%;
    top: 20%;
    left: 50%;
    box-shadow: rgba(0,0,0,0.2) 0 0 60px;
    border-radius: 20px;
}

.tinymodal-title {
    background: #f47920;
    color: #FFF;
    padding: 30px;
    font-size: 22px;
    position: relative;
    border-radius:7px 7px 0 0;
}

.tinymodal-close {
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -.5em;
    font-size: 150%;
    line-height: 1;
    cursor: pointer
}

.tinymodal-content {
    // padding: 30px;
    background: #fff;
    border-radius: 0 0 7px 7px;
    p {
        margin:0;
        line-height:28px;
    }
    a.button {
            display: inline-block;
    margin: 20px 20px 0 0;
    padding: 15px 20px;
    border-radius: 5px;
    color: #6F717D;
    font-weight: 600;
    text-decoration: none;
    border: 2px solid #eee;
    .transition;
        &:hover {
            background:@color-green;
            color: #FFF;
            border-color: @color-green;
        }
    }
}

/*  SERVICE ALERTS
----------------------------------------------- */

.alert-close {
  position: absolute;
  right: 30px;
  top: 36px;
  cursor: pointer;
  font-size: 60px;
  z-index: 1;
  line-height: 60px;
  text-align: center;
  .transition;
  &:before {
    content: '';
    background: @color-orange;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    position: absolute;
    top: 4px;
    left: -13px;
    z-index: -1;
    transform: scale(0, 0);
    .transition;
  }
  &:hover {
    color: #FFF;
    &:before {
      transform: scale(1, 1);
    }
  }
}


.sharedAlert {
  background: #e2e3e4;
  color: #323137;
  overflow: auto;
  margin: 59px auto -60px;
  padding: 30px;
  @media @desktop {
    padding: 30px 0;
  }
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    position: relative;
    @media @desktop-wide {
      padding: 0 20px;
      width: auto;
    }
  }
  .alert-content {
    padding-right: 80px;
  }
  h2 {
    margin-top: 0;
  }
  .alert-close {
    top: 50%;
    font-family: initial;
    line-height: normal;
    font-size: initial;
    &:before {
      top: -20px;
      left: -20px;
    }
    svg, img {
      position: absolute;
      top:0;
    }
  }
}
