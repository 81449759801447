img {
    max-width: 100%;
}

.grid-section {
    // padding: 0 20px;
}

.row--grid {
    margin: 30px -15px;
    display: flex;
    flex-wrap: wrap;

    @media @desktop {
        margin: 30px -20px;
    }

    .column {
        padding: 0 15px;

        @media @desktop {
            margin-bottom: 30px;
        }

        > div {
            &:not(:last-child) {
                margin-bottom: 20px;
        }
        }

        h1, h2, h3, h4, h5, p {
            &:first-child {
                margin-top: 0;
            }
        }

        img:not(.content-panel__image):not(.product__image) {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    blockquote {
        margin: 0;
        font-size: 30px;

        &:before, &:after {
            font-family: fontAwesome;
        }

        &:before {
            content: '\f10d';
            margin-right: 10px;
        }

        &:after {
            content: '\f10e';
            margin-left: 10px;
        }
    }

    @supports (aspect-ratio: 16/9) {
        iframe {
            width: 100%;
            height: auto;
            aspect-ratio: ~"16/9";
        }
    }
}

.link-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:before {
            content: '\f054';
            font-family: 'fontAwesome';
            position: absolute;
            top: 5px;
            left: 0;
            font-size: 12px;
        }
    }

    &__link {
        color: inherit;
    }
}

li {
    margin-bottom: 20px;
}

// .sandyford-header {
//     @media @mobile {
//         position: fixed !important;
//         width: 100% !important;
//     }

//     .content {
//         padding: 0 20px !important;
//     }
// }

.label--hidden {
    visibility: hidden;
    display: none;
}