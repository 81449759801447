.content-width {
    max-width: 1200px;
    margin: 0 auto;
}

.transition {
    transition: all .4s ease;
}

.no-scroll {
    overflow: hidden;

    &--tablet {
        @media @tablet {
            overflow: hidden;
        }
    }
}

.link--cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    text-indent: -999999px;
}

.label--hide {
    text-indent: -999999px;
    @media @mobile {
        visibility: hidden;
        display: none;
    }
}

.hidden {
    display: none;
}