.accordion-row {
    margin-top: 15px;
    .teaser-title {
        background-color: #eee;
        padding: 20px 25px;
        cursor: pointer;
        display: flex;
        user-select: none;
        &:after {
            content: '\f078';
            font-family: 'fontAwesome';
            margin-left: auto;
            .transition;
        }
    }
    .abstract {
        max-height: 0;
        overflow: hidden;
        padding: 0 25px;
        background-color: lighten(#eee, 4%);
        transition: all ease .3s;
        &--short {
            max-height: none;
            padding: 10px 25px;
            background: none;
            transition: none;
        }
        p {
            // font-size: 1.7rem;
            // line-height: 1.7;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &--active {
        .teaser-title {
            background-color: @color-green;
            // color: #FFF;
            &:after {
                transform: scaleY(-1);
            }
        }
        .abstract {
            max-height: 1000px;
            padding: 15px 25px;
        }
        .abstract--short {
            max-height: 0;
            padding: 0 25px;
        }
    }
}